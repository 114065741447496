import React from 'react'
import '../styles/Body.css'
import '../styles/CommissionBody.css'

export default function CommissionBody () {
  return (
    <div className='commbody'>
      <div className='content' style={{ textAlign: 'center', width: '50%' }}>
        <h3 className="spacerh">Commissions</h3>
        <p>If you have a mod you want to have developed. Then you can work with me to have it made.<br/>
          The price will depend on the scale of the project. Please include all details of the project so that I may reasonably price it.</p>

        <p>I have experience in many fields of mod development; however for models or textures it might be better to supply yourself.<br/>
        I can develop mods for any version of Minecraft as well as port open-source mods to any version you need.</p>
        <h3>Contact</h3>
        <p>I do not have a specialized portal for commissions right now, so please contact me on Discord.</p>
        <a href="https://discord.gg/G6HrJk7BBc">Discord Server</a>
        <p><b>Or Send a DM</b>: Matthe815#1507</p>
      </div>
    </div>
  )
}